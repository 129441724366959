import React from "react";

import Layout from "../components/Shared/Layout/Layout";
import { PageBreadcrumb } from "../components/Shared/PageBreadcrumb/PageBreadcrumb";
import { NotFoundPageBreadcrumb } from "../components/404/404PageBreadCrumb/404PageBreadCrumb";
import { PageNotFoundSection } from "../components/404/PageNotFoundSection/PageNotFoundSection";
import { PageNotFoundHomeLinkDivider } from "../components/404/PageNotFoundHomeLinkDivider/PageNotFoundHomeLinkDivider";
import { ContactFormSection } from "../components/Shared/ContactFormSection/ContactFormSection";

const NotFoundPage = () => (
  <Layout>
    <NotFoundPageBreadcrumb />
    <PageNotFoundSection />
    <PageNotFoundHomeLinkDivider />
    <ContactFormSection />
  </Layout>
);

export default NotFoundPage;
