import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Section/Section";
import { HeaderUnderline } from "../../Shared/HeaderUnderline/HeaderUnderline";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import SvgLogoDark from "../../../assets/LogoDark";

export interface PageNotFoundSectionProps {
  className?: string;
}

const PageNotFoundSectionInner = (props: PageNotFoundSectionProps) => {
  const { t } = useTranslation("common");
  return (
    <Section className={props.className}>
      <Container>
        <HeaderUnderline align="center">
          {t("notFound.notFound")}
        </HeaderUnderline>
        <div className="logo">
          <SvgLogoDark height={120} width={300} />
        </div>
      </Container>
    </Section>
  );
};

export const PageNotFoundSection = styled(PageNotFoundSectionInner)`
  .logo {
    display: flex;
    justify-content: center;
  }
`;
