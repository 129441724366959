import React, { ReactNode } from "react";
import styled from "styled-components";
import { makeStyles, Container, Button } from "@material-ui/core";
import { muiTheme } from "../../../lib/Theme";
import { Section } from "../Section/Section";
import { Heading } from "../Heading/Heading";
import { routes } from "../../../lib/routes";
import { AltroButton } from "../../home/DocumentsLinkSection/DocumentsLinkSection";

export interface DividerProps {
  className?: string;
  children?: ReactNode;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [muiTheme.breakpoints.down("md")]: {
      flexFlow: "column"
    }
  }
});

const DividerInner = (props: DividerProps) => {
  const styles = useStyles();
  return (
    <Section background className={props.className}>
      <Container className={styles.container}>{props.children}</Container>
    </Section>
  );
};

export const Divider = styled(DividerInner)`
  ${muiTheme.breakpoints.down("md")} {
    ${Heading} {
      text-align: center;
    }
  }

  ${Heading} {
    margin: 0;
    font-size: 2.6rem;
  }
`;
