import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { routes } from "../../../lib/routes";
import { muiTheme } from "../../../lib/Theme";
import { Divider } from "../../Shared/Divider/Divider";
import { Heading } from "../../Shared/Heading/Heading";
import { CtaButton } from "../HeroSection/HeroSection";

export interface DocumentsLinkSectionProps {
  className?: string;
}

export const AltroButton = styled(CtaButton)`
  padding: 15px 20px;
  white-space: nowrap;
  margin-left: 20px;

  ${muiTheme.breakpoints.down("md")} {
    margin-top: 20px;
    margin-left: 0;
  }
`;

const DocumentsLinkSectionInner = (props: DocumentsLinkSectionProps) => {
  const { t } = useTranslation("home");
  return (
    <Divider className={props.className}>
      <Heading>{t("documentsLink.title")}</Heading>
      <AltroButton to={routes.documents.to}>
        {t("documentsLink.linkText")}
      </AltroButton>
    </Divider>
  );
};

export const DocumentsLinkSection = styled(DocumentsLinkSectionInner)``;
