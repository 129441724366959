import React from "react";
import styled from "styled-components";
import { Divider } from "../../Shared/Divider/Divider";
import { Heading } from "../../Shared/Heading/Heading";
import { useTranslation } from "react-i18next";
import { AltroButton } from "../../home/DocumentsLinkSection/DocumentsLinkSection";
import { Link } from "gatsby";

export interface PageNotFoundHomeLinkDividerProps {
  className?: string;
}

const PageNotFoundHomeLinkDividerInner = (
  props: PageNotFoundHomeLinkDividerProps
) => {
  const { t } = useTranslation("common");
  return (
    <Divider className={props.className}>
      <Heading>{t("notFound.linkToHome")}</Heading>
      <Link to={"/"}>
        <AltroButton>{t("routes.home")}</AltroButton>
      </Link>
    </Divider>
  );
};

export const PageNotFoundHomeLinkDivider = styled(
  PageNotFoundHomeLinkDividerInner
)``;
