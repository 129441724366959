import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PageBreadcrumb } from "../../Shared/PageBreadcrumb/PageBreadcrumb";

export interface NotFoundPageBreadcrumbProps {
  className?: string;
}

const NotFoundPageBreadcrumbInner = (props: NotFoundPageBreadcrumbProps) => {
  const { t } = useTranslation("common");
  return <PageBreadcrumb breadCrumbText={t("notFound.code")} />;
};

export const NotFoundPageBreadcrumb = styled(NotFoundPageBreadcrumbInner)``;
