import React from "react";
import styled from "styled-components";
import { Container, Typography } from "@material-ui/core";

export interface PageBreadcrumbProps {
  className?: string;
  breadCrumbText: string;
}

const PageBreadcrumbInner = (props: PageBreadcrumbProps) => {
  const { breadCrumbText } = props;
  return (
    <div className={props.className}>
      <Container>
        <div className="text">{breadCrumbText}</div>
      </Container>
    </div>
  );
};

export const PageBreadcrumb = styled(PageBreadcrumbInner)`
  display: flex;
  align-items: center;

  background: #f6f6f6;
  height: 50px;
  width: 100%;

  .text {
    text-transform: capitalize;
    color: ${props => props.theme.color.primary};
    font-weight: 600;
    font-size: 1.6rem;
  }
`;
